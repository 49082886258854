<!-- Basic template with only header logo and footer -->
<template>
    <div>
        <Html class="h-full bg-gray-100">

        <Head>
            <Title>WeTix - Tunes Festivals Admin</Title>
        </Head>

        </Html>

        <Body class="h-full"></Body>
        <header class="relative bg-white border-b border-gray-200 text-sm font-medium text-gray-700">
            <div class="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
                <div class="relative flex justify-end sm:justify-center">
                    <a href="/tunes-admin" class="absolute left-0 top-1/2 -mt-4">
                        <span class="sr-only">WeTix</span>
                        <img src="https://imagedelivery.net/kCXBEBYe-fVFQQ06UGmg1w/c321e293-0ab8-4958-1577-dbe12c4c1600/200pxw"
                            alt="WeTix" class="h-8 w-auto" />
                    </a>
                </div>
            </div>
        </header>
        <div class="relative bg-primary-600">
        <!-- <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                <div class="pr-16 sm:text-center sm:px-16">
                    <p class="font-medium text-white">
                        <span class="hidden md:inline">Thank you for being a Beta tester.</span>
                       
                    </p>
                </div>
                <div
                    class="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start"
                >
                    <button
                        type="button"
                        class="flex p-2 rounded-md hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-white"
                    >
                        <span class="sr-only">Dismiss</span>
                        <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                </div>
                </div>-->
        </div>
        <main class="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
            <slot />
        </main>
        <footer aria-labelledby="footer-heading" class="bg-white">
            <h2 id="footer-heading" class="sr-only">Footer</h2>
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div class="border-t border-gray-100 py-10 text-center">
                    <p class="text-sm text-gray-500">&copy; 2022 WeTix Ltd. All rights reserved.</p>
                </div>
            </div>
        </footer>
    <!-- <footer class="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div class="border-t border-gray-200 py-12 text-center md:flex md:justify-between">
                <p class="text-base text-gray-400">&copy; WeTix Ltd. All rights reserved.</p>
                <div class="mt-6 flex justify-center space-x-8 md:mt-0">
                   
                </div>
            </div>
            </footer>-->
    </div>
</template>

<script>
import { XIcon } from '@heroicons/vue/outline';
export default {
    components: {
        XIcon
    },
    setup() {
        return {

        }
    },
}
</script>